import React from "react";

import tiktok from "../images/video.png";
import twitter from "../images/twitter.svg";
import instagram from "../images/instagram.svg";
import youtube from "../images/youtube.png";

const Footer = () => {
  return (
    <div
      id="contact"
      className="font-lato items-center flex gap-1 flex-col h-98 bg-navy text-cream  p-16 md:flex-row justify-between md:items-start text-lg"
    >
      <div>
        <div className="p-2">
          JENGA MTOTO FOUNDATION, <br />
          Mpererwe mugalu zone <br />
          off Gayaza Road,
          <br />
          Kamapala,
          <br />
          Uganda
          <br />
        </div>
        <div className="p-2">
          MTN: <a href="tel:+256 775 780363">+256 775 780363</a> <br />
          <a href="tel:+256 708 776443">Airtel: +256 708 776443</a>
        </div>
        <div className="p-2">
          <a
            href="mailto:jengamtotofoundation@gmail.com?subject=Contact Jenga"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            jengamtotofoundation@gmail.com
          </a>
        </div>
      </div>
      <div>
        <div className="flex flex-row pt-2 pr-6">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.tiktok.com/@jengamtotofoundation"
          >
            <div className="p-2 cursor-pointer">
              <img src={tiktok} alt="tiktok logo" width="40px" height="40px" />
            </div>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/jengamtoto?lang=en"
          >
            <div className="p-2 cursor-pointer">
              <img
                src={twitter}
                alt="twitter logo"
                width="40px"
                height="40px"
              />
            </div>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/jengamtoto/"
          >
            {" "}
            <div className="p-2 cursor-pointer">
              <img
                src={instagram}
                alt="instagram logo"
                width="40px"
                height="40px"
              />
            </div>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCPQtAoFAqDCtle7vRqtZwEQ"
          >
            <div className=" pl-1 cursor-pointer">
              <img src={youtube} alt="twitter logo" className="h-14 w-11" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
