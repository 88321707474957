import React from "react";


const HeroBox = (props) => {
  return (
    <div className="h-50 lg:h-54">
      <h3 className="font-poppins text-3xl text-navy font-semibold pb-2 md:text-3xl ">{props.title}</h3>
      <p className="font-lato md:text-lg ">{props.text}</p>
    </div>
  );
}



export default HeroBox;
