import React from "react";
import { Link } from "react-scroll";

import logo from "../images/Jenga Logo with Text.png";
import menu from "../images/menu.png";
import cross from "../images/cross.png";

const Navbar = ({ isOpen, toggle }) => {
  return (
    <nav className="flex justify-between item-center h-21 text-lg font-poppins">
      <div className="static pl-9">
        <img
          className="absolute object-cover h-20 w-20 pt-3 pb-2 md:h-40 md:w-40 "
          src={logo}
          alt=""
        />
      </div>

      <div className="md:hidden p-6 pr-12 cursor-pointer" onClick={toggle}>
        <img className="md:hidden h-7 w-7" src={isOpen ? cross : menu} alt="" />
      </div>

      <div className="pr-10 md:flex flex-row hidden text-navy font-semibold sticky top-0 z-50 bg-white">
        <Link
          to="about"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="transition duration-150 ease-in-out cursor-pointer hover:bg-navy hover:text-cream"
          href=""
        >
          <div className="p-6 pt-7">About Us</div>
        </Link>
        <Link
          to="services"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="transition duration-150 ease-in-out cursor-pointer hover:bg-navy hover:text-cream"
        >
          <div className="p-6 pt-7">Our Services</div>
        </Link>
        <Link
          to="what"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="transition duration-150 ease-in-out cursor-pointer hover:bg-navy hover:text-cream"
        >
          <div className="p-6 pt-7">What We Do</div>
        </Link>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={600}
          className="transition duration-150 ease-in-out cursor-pointer hover:bg-navy hover:text-cream"
        >
          <div className="p-6 pt-7">Contact Us</div>
        </Link>
        <div className="bg-navy text-cream transition duration-150 ease-in-out cursor-pointer hover:bg-cream hover:text-navy">
          <a
            rel="noopener noreferrer"
            href="https://gofund.me/04ad86dd"
            className="p-6 pt-7 flex justify-center"
            target="_blank"
          >
            DONATE
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
