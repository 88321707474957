import React from "react";

const conditions = [
  "Attention Deficit Hyperactivity",
  "Autism Spectrum Disorder",
  "Global development delays",
  "Cerebral Palsy",
  "Delayed Milestones",
  "Down Syndrome",
  "etc.",
];

const listItems = conditions.map((condition) => <li>- {condition}</li>);

const Conditions = () => {
  return (
    <div className="bg navy pb-2 text-white">
      <div className="font-lato md:text-lg pt-2 lg:text-xl">
        Conditions We Address:
      </div>
      <div className="flex font-lato md:text-lg pt-2 lg:text-xl pl-4">
        <ul>{listItems}</ul>
      </div>
    </div>
  );
};

export default Conditions;
