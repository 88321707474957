import React from "react";
import Conditions from "./conditions";

const ContentBox = (props) => {
  return (
    <div className={`flex-grow h-50 p-8 bg-${props.color}`}>
      <div
        className={`font-lato md:text-lg pt-2 lg:text-xl text-${props.textColor} `}
      >
        <div className="pb-8 text">
          {props.text}
          <br />
          <br />
          {props.text2}
        </div>
        <img src={props.source} alt="" />
      </div>
      <div className={`${props.show}`}>
        {" "}
        <Conditions />
      </div>
    </div>
  );
};

export default ContentBox;
