import React from "react";
import { Link } from "react-scroll";

import HeroBox from "./heroBox";
import group from "../images/studentsEating.jpg";

const Hero = () => {
  return (
    <section className="flex flex-col shrink-0 ">
      <div className="grid md:grid-cols-2 ">
        <div className="font-poppins p-16 md:mt-14 pr-8 flex justify-center align-start flex-col">
          <h1 className="font-poppins text-5xl leading-snug">
            Give your child the special start they deserve.
          </h1>
          <h2 className="font-lato text-3xl text-blue mt-2 ">
            See the able not the label
          </h2>
          <h2 className="font-lato mt-1">
            Bring your child to Jenga Mtoto today
          </h2>
          <Link
            to="services"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <button className="bg-navy text-cream text-base font-semibold py-4 px-8 rounded-sm mt-10 hover:bg-cream hover:text-navy transition duration-200 ease-in-out">
              Find Out More
            </button>
          </Link>
        </div>
        <img
          src={group}
          className="object-cover h-52 w-full md:h-full md:w-auto "
          alt=""
        />
      </div>

      <div
        id="about"
        className="grid grid-cols-1 md:grid-cols-3 md:mt-2  gap-10 p-16 "
      >
        <HeroBox
          title="About Us"
          text="Jenga Mtoto Foundation is a learning centre that supports and guides parents  to access therapeutic services for their children with learning disabilities."
        />
        <HeroBox
          title="Our Mission"
          text="To provide quality comprehensive rehabilitation services to children living with physical and/or learning challenges limiting their levels of functional performance."
        />
        <HeroBox
          title="Core values"
          text="Unending passion for children, quality and professionalism, transparency, accountability and unity."
        />
      </div>
    </section>
  );
};

export default Hero;
