import React from "react";

import ImageBox from "./imageBox";
import ContentBox from "./contentBox";

import staff from "../images/sittingRoom.png";
import therapist2 from "../images/student2-2.jpg";
import compound from "../images/compound-2.jpg";

const Content = () => {
  return (
    <div id="services" class=" flex flex-col py-8">
      <div className="bg-navy">
        <div className="text-center font-poppins p-12 text-3xl pt-10 text-cream font-semibold pb-1 md:text-3xl lg:text-4xl">
          Conditons We Address
        </div>
        <div className="grid grid-cols-1 pb-0 bg-navy md:grid-cols-2 items-center md:py-10 p-8">
          <ImageBox src={staff} />
          <ContentBox
            title="Conditions We Address"
            text="We welcome all children with learning difficulties aged 0 - 12 years old to our day program. We also run run a boarding program for children aged 4-12 years old."
            textColor="white"
          />
        </div>
      </div>
      <div className="text-center font-poppins text-3xl p-8 pt-10 text-navy font-semibold pb-1  lg:text-4xl">
        Our Services
      </div>
      <div className="bg-white grid  grid-cols-1 md:grid-cols-2 items-center pt-10 p-8 text-navy">
        <ImageBox src={compound} />
        <ContentBox
          title="Our Services"
          text="When erolled, the children undergo an intervention program from all therapy departments including occupational and speach therapists to establish the child's therapy needs. "
          text2="We also welcome volunteering, internship and supervision, research data collection, parent groups and caretaker training."
          textColor="navy"
          show="hidden"
        />
      </div>
      <div className="bg-navy">
        <div className="text-center font-poppins text-3xl p-8 pt-10 text-cream font-semibold pb-1 md:text-3xl lg:text-4xl">
          Special Needs Education
        </div>
        <div className="grid  grid-cols-1  md:grid-cols-2 items-center pt-9 p-8">
          <ContentBox
            title="Special Needs Education"
            text="Our special educational needs program aims to provide individualised and modified strategies to provide learners to acquire academic skills."
            text2="We also support and encourage development of talents while creating a community of life-long learners."
            textColor="white"
            show="hidden"
          />
          <ImageBox src={therapist2} />
        </div>
      </div>
    </div>
  );
};

export default Content;
