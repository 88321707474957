import React from "react";

const ImageBox = (props) => {
  return (
    <div>
      <img
        class="object-contain max-h-54 h-54 w-full p-8 "
        src={props.src}
        alt=""
      />
    </div>
  );
};

export default ImageBox;
