import React from "react";
import { Link } from "react-scroll";

const Dropdown = ({ isOpen, toggle }) => {
  return (
    <div
      className={
        isOpen
          ? "grid grid-rows-4 mt-2 text-center text-cream items-centertext-cream font-poppins"
          : "hidden"
      }
      onClick={toggle}
    >
       <div
        className="bg-navy transition duration-150 ease-in-out cursor-pointer hover:bg-navy hover:opacity-75"
      >
        <a
            rel="noopener noreferrer"
            href="https://gofund.me/04ad86dd"
            className="p-6 pt-7 flex justify-center"
            target="_blank"
          >
            DONATE
          </a>
      </div>
      <Link
        to="about"
        className="bg-navy transition duration-150 ease-in-out cursor-pointer hover:bg-navy hover:opacity-75"
        href=""
      >
        <div className="p-6 pt-7">What We Do</div>
      </Link>
      <Link
        to="services"
        className="bg-navy transition duration-150 ease-in-out cursor-pointer hover:bg-navy hover:opacity-75"
      >
        <div className="p-6 pt-7">Our Services</div>
      </Link>
      <Link
        to="about"
        className="bg-navy transition duration-150 ease-in-out cursor-pointer hover:bg-navy hover:opacity-75"
      >
        <div className="p-6 pt-7">Gallery</div>
      </Link>
      <Link
        to="contact"
        className="bg-navy transition duration-150 ease-in-out cursor-pointer hover:bg-navy hover:opacity-75"
      >
        <div className="p-6 pt-7">Contact Us</div>
      </Link>
    </div>
  );
};

export default Dropdown;
