import React, { Component } from "react";

class Newsletter extends Component {
  state = {
    email: "",
    id: "",
  };

  handleChange = (e) => {
    this.setState({ email: e.target.value.trim() });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(e)
    let body = {
      email: this.state.email,
    };
    const options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-key": `${process.env.REACT_APP_API_KEY}`,
      },
    };

    const url = `https://whispering-basin-02126.herokuapp.com/https://api.sendinblue.com/v3/contacts`;
    // console.log(body)
    // console.log(url)
    // console.log(options)
    if (this.state.email) {
      fetch(url, options)
        .then((res) => res.json())
        .then((myJson) => {
          // console.log('Request', myJson)
          const resId = JSON.stringify(myJson.id);
          this.setState({ id: resId });
          // console.log("resId",resId)
          // console.log(this.state.email)
        })
        .catch((err) => console.log(err));
    }
  };

  renderStatusCode() {
    if (this.state.id === undefined) {
      return (
        <div
          id="popDiv"
          className="absolute bottom-0 left-0 top-0 right-0 flex justify-center items-center bg-cream"
        >
          <div className=" font-poppins font-semibold  text-navy text-3xl ">
            Thanks for subscribing!{" "}
          </div>
        </div>
      );
    } else if (this.state.id === undefined) {
      return (
        <div
          id="popDiv"
          className="absolute bottom-0 left-0 top-0 right-0 flex justify-center items-center bg-cream"
        >
          <div className=" font-poppins font-semibold  text-navy text-3xl ">
            User already subscribed{" "}
          </div>
        </div>
      );
    } else {
      console.log("");
    }
  }

  render() {
    return (
      <div className="flex">
        <div className="flex flex-col p-8 w-full items-center md:flex-row h-full rounded-sm gap-12">
          <div className="relative mt-10  w-full md:h-54 m-8 text-center bg-navy shadow-sm">
            <div className="p-9">
              <div className="pt-2 self-center text-3xl text-cream font-poppins font-semibold">
                Stay in Touch
              </div>
              <div className="text-cream pt-2 font-lato">
                Subscribe to our mailing list to stay updated!
              </div>
              <form onSubmit={this.handleSubmit}>
                <input
                  required
                  name="email"
                  type="email"
                  className="w-full mt-12 border-b-2 border-cream font-lato text-xl p-2 focus:outline-none focus:border-navy transition duration-300 ease-in-out"
                  placeholder="Enter your email address"
                  onChange={this.handleChange}
                  value={this.state.email}
                />
                <button
                  type="submit"
                  className="bg-cream text-navy text-base font-semibold py-4 px-8 rounded-sm mt-10 hover:bg-white transition duration-200 ease-in-out"
                >
                  Subscribe
                </button>
                <div className="relative"></div>
              </form>
            </div>
            {this.renderStatusCode()}
          </div>
        </div>
      </div>
    );
  }
}

export default Newsletter;
